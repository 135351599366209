<template>
  <section id="banner">
    <div class="container overflow-hidden">
      <div class="row">
        <div
          class="
            col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12
            pb-5
            ps-0
            pe-0
            mx-auto
            mb-4
          "
        >
          <div class="bg-gray rounded p-5 insurance-banner-items-container">
            <div class="row contact-banner-item">
              <div
                class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8
                  pt-2
                  ps-5
                "
              >
                <p class="h2 text-main text-uppercase fw-bold">
                  SIĞORTA XİDMƏTLƏRİ
                </p>
                <p class="font-size-default text-gray mt-4">
                  Şirkət ilk rahat onlayn sığorta məqsədi ilə 2019-cu ildə bir
                  qrup sahibkar<br />
                  tərəfindən yaradılıb və avtomobil sığortasından başlamaq
                  qərarına gəlib.
                </p>
              </div>
              <div
                class="
                  col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4
                  pt-2
                  pe-4
                "
              >
                <img src="@/assets/images/ins-banner.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section id="cmtpl-insurance-solutions">
    <div class="container overflow-hidden">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pb-5 mb-4 mt-4"
      >
        <p class="h4 text-main fw-bold ps-0">Avto İcbari</p>
        <div class="row">
          <div
            class="
              col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6
              pb-5
              mb-4
              mt-4
              small
              text-gray text-justify
            "
          >
            Avtonəqliyyat vasitəsi sahiblərinin mülki məsuliyyətinin icbari
            sığortası mexaniki avtonəqliyyat vasitələrinin Azərbaycan
            Respublikasının ərazisində istifadəsi nəticəsində fiziki şəxslərin
            sağlamlığına, həmçinin fiziki və hüquqi şəxslərin əmlakına vurduğu
            zərərlərin əvəzinin ödənilməsini nəzərdə tutur. Sığorta ödənişi
            Sizin təqsiriniz üzündən baş vermiş yol-nəqliyyat hadisəsi
            nəticəsində zərər çəkən şəxslərə ödənilir.
            <br /><br />
            Zərərin əvəzinin ödənilməsi üçün, zərərvuranın avtonəqliyyat
            vasitəsindən istifadə ilə bağlı mülki məsuliyyətini sığortalayan
            sığorta şirkətinə müraciət edilməlidir. Müraciət əsasən sığorta
            şirkətiniz sizin üçün dəymiş zərərin miqyasını müəyyən edərək avto
            nəqliyyat vasitənizin təmiri üçün ödəniş edir və ya müvafiq
            avtomobil servisində təmir etdirərək sizə təhvil verir.
            <br /><br />

            Bu sığorta növ “İcbari sığortalar haqqında” Azərbaycan
            Respublikasının qanununla təmzinlənir.

            <div class="mt-5">
              <a
                href="#"
                class="
                  btn-default btn-blue
                  text-decoration-none text-white
                  d-inline-block
                  position-absolute
                  border-0
                "
                >Sığortanı al</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>